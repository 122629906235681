import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@apmg/titan';
import Link from 'next/link';
import Byline from '../Byline/Byline';
import AdvertisementBanner from 'components/Advertisement/AdvertisementBanner';

const ContentHeader = (props) => {
  let authorsTag = [];

  let checkTagName = `${props?.tag?.tagName}`;

  if (props.authors?.length > 0) {
    props.authors.forEach((author) => {
      authorsTag.push(author.title);
    });
  }
  if (!checkTagName) {
    checkTagName = 'default';
  }
  const authorTosStr = JSON.stringify(authorsTag);
  const contentTopicHeaderRef = useRef(null);
  //No array passed for useEffect expected behavior is to let useEffect run on rerender
  // useEffect(() => {
  //   if (contentTopicHeaderRef) {
  //     window.dataLayer = window.dataLayer || [];
  //     window.dataLayer.push({
  //       event: 'sendUWContentTopic',
  //       contentTopic: checkTagName,
  //     });
  //   } else {
  //     console.error('you broke the ads');
  //   }
  // }, [checkTagName]);

  return (
    <header className="story-header type-sm type-mb">
      <div className="premium-underwriting">
        <AdvertisementBanner
          id="premium_ad"
          sizes={[[320, 25]]}
          sizeMap={{ 0: [320, 25] }}
          customCss="underwriting"
          primaryCollection={props.primaryCollection}
        />
      </div>
      {props.tag && (
        <div
          className="story-collection"
          data-mpr-content-topic={props.tag.tagName}
          ref={contentTopicHeaderRef}
        >
          <Link href={`/${props.tag.to}`} className="link link-none">
            {props.tag.tagName}
          </Link>
        </div>
      )}
      <div className="story-meta">
        {props.authors?.length !== 0 && (
          <div
            className="story-byline"
            data-testid="contentByline"
            data-mpr-authors={authorTosStr}
          >
            <Byline authors={props.authors} />
          </div>
        )}
        {props.dateline && (
          <div className="story-dateline">{props.dateline} </div>
        )}
        {props.publishDate && (
          <div className="story-pubdate">{props.publishDate}</div>
        )}
        {props.updatedPublishDate && (
          <div className="story-updated">
            <strong>Updated: </strong>
            {props.updatedPublishDate}
          </div>
        )}
      </div>
      <Heading
        level={props.headingLevel ? props.headingLevel : 1}
        className="hdg hdg-1 hdg-headline"
      >
        {props.title}
      </Heading>
      {props.subtitle && (
        <p className="story-subtitle type-lg" data-testid="contentSubtitle">
          {props.subtitle}
        </p>
      )}
    </header>
  );
};

ContentHeader.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  headingLevel: PropTypes.number,
  publishDate: PropTypes.node,
  updatedPublishDate: PropTypes.node,
  subtitle: PropTypes.string,
  dateline: PropTypes.string,
  primaryCollection: PropTypes.string,
  tag: PropTypes.shape({
    to: PropTypes.string,
    href: PropTypes.string,
    tagName: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  underwriting: PropTypes.bool,
};

export default ContentHeader;
